ion-menu ion-content {
  /* --padding-top: 20px; */
  --padding-bottom: 20px;

  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/* Remove background transitions for switching themes */
ion-menu ion-item {
  --transition: none;
}

/*
 * Material Design Menu
*/

ion-menu.md ion-list-header {
  padding-left: 18px;
  padding-right: 18px;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 450;
}

ion-menu.md ion-item {
  --padding-start: 20px;
  margin-right: 10px;
  border-radius: 50px;
  font-weight: 500;
}

ion-menu.md ion-list-header,
ion-menu.md ion-item ion-icon {
  color: var(--ion-color-step-650, #5f6368);
}

ion-menu.md ion-list:not(:last-of-type) {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

.col-right,
.col-left {
  display: inline-block;
  width: 50%;
}

.app-header-logo {
  display: inline-block;
  max-width: 156px;
  margin-left: 40px;
  margin-top: 17px;
  margin-bottom: 17px;
}

ion-menu.md ion-list {
  width: calc(100% - 40px) !important;
  padding: 20px 0;
  width: 200px;
  float: right;
  margin: 0 20px;
}

.mob-header {
  display: block;
  border-bottom: 1px solid #d6d6d6;
}

.mob-header .app-header-logo {
  max-width: 120px;
  margin: 10px 20px;
}

.mob-header .button {
  float: right;
}

.mob-header .dropdown {
  position: absolute;
  right: 0;
}

.product-listing-mob {
  padding: 8px;
}

.product-listing-mob img {
  border-radius: 5px;
  overflow: hidden;
}

.product-listing-mob h2 {
  font-size: 12px;
  font-weight: 700;
}

.product-listing-mob h3 {
  font-size: 12px;
}

.product-listing-mob p {
  font-size: 12px;
}

.product-listing-mob button {
  margin-top: 0;
}

.product-listing-mob p span:after {
  content: ",";
  margin-right: 3px;
}

.product-listing-mob p span:last-child::after {
  content: "";
}

.product-listing-mob .button {
  font-size: 10px;
  padding: 0px;
  line-height: 10px;
  height: 26px;
  margin-top: 5px;
}

.product-listing-mob .text-center p {
  font-size: 10px;
}

.order-in-progress.mobile-view .product-progress-block.progress-flat ul {
  margin-top: 0;
  padding: 0 0 0 40px;
}

.order-in-progress.mobile-view .product-progress-block.progress-flat ul li {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.selected {
  color: red !important;
}

.selected .ionicon,
.selected .ionicon path {
  fill: red !important;
  stroke: red !important;
}
