@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body,
table,
p,
div,
section,
h1,
h2,
h3,
h4,
h5,
h6,
* {
    font-family: 'Poppins', sans-serif;
}

.header-md::after {
    background-image: none;
}

a {
    text-decoration: none;
}

.button {
    margin-top: 15px;
    text-transform: capitalize;
    font-size: 16px;
    box-shadow: 0 0 0;
    height: 36px;
}

.small-form,
.large-form,
.no-form {
    width: 480px;
    padding: 30px 15px 40px;
    margin: auto;
    border: 1px solid #d6d6d6;
    border-radius: 7px;

    hr {
        background: #d6d6d6;
        margin: 30px 0;
    }

    h3 {
        padding: 0 15px;
    }

    .adjust-row {
        margin: 10px 0;
        display: block;
        text-align: center;
    }

    .adjust-margin {
        margin: 0 8px;
    }
}

.large-form {
    width: 720px;
}

.no-form {
    width: 640px;
    border: none;
    margin-left: 20px;
    padding: 0;

    Ion-Item {
        margin-bottom: 10px;
    }

    .list-md {
        margin: 0;
    }

    h4 {
        font-size: 15px;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

/* login page styles */
#login-page,
#signup-page,
#support-page {
    .login-logo {
        padding: 20px 0;
        margin-bottom: 60px;
        text-align: center;
    }

    .login-logo img {
        max-width: 240px;
    }

    .list {
        margin-bottom: 0;
    }
}

.card-content-md {
    padding: 0 !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.button,
.button-solid,
.button-native,
:host(.button-solid),
.ion-content,
.display-card,
.filters-row .item-interactive.item-select,
.filters-row .searchbar-should-show-clear,
.filters-row .searchbar-input-container.sc-ion-searchbar-md-h,
.filters-row .searchbar-input.sc-ion-searchbar-md,
* {
    box-shadow: 0 0 0 !important;
    --box-shadow: 0 0 0 !important;
}

.ion-content,
.display-card,
.filters-row .item-interactive.item-select,
.filters-row .searchbar-should-show-clear,
.filters-row .searchbar-input-container.sc-ion-searchbar-md-h,
.sc-ion-select-popover-md.list-md,
.content-sizing.content-ltr {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}

.split-pane-side.app-left-navigation {
    min-width: 280px;
    max-width: 15%;
    border-right: 1px solid #d6d6d6 !important;
    // display: block !important;
}

.split-pane-main.menu-content {
    display: block;
}

ion-menu.md ion-list ion-icon {
    margin-right: 20px;
}

.listing-block {
    display: block;
    max-width: 900px;

    .toggle-menue-type {
        float: right;
    }
}

.banner-row {
    display: inline-block;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
}

.banner-row .banner-col {
    float: left;
}

.banner-row .banner-col.banner-col-small {
    width: 25%;
}

.banner-row .banner-col.banner-col-large {
    width: 50%;
}

.banner-row .banner-col p {
    font-size: 12px;
    margin: 16px 0;
}

.banner-row .banner-col span {
    font-size: 20px;
    font-weight: 700;
}

.banner-row .banner-col ion-icon {
    margin: 5px 15px;
    transform: scale(2);
    float: left;
    position: relative;
    top: 5px;
}

.banner-row .banner-col img {
    margin-right: 7px;
    border-radius: 3px;
}

hr {
    height: 1px;
    border-width: 0;
    box-sizing: content-box;
    background: #e6e6e6;
    margin: 40px 10px;
}

.list-md {
    margin: 20px 10px;
    padding: 0;
}

.menu-card {
    max-width: calc(25% - 10px);
    float: left;
    padding: 10px;
    margin-right: 12.5px;
    margin-bottom: 12.5px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
}

.menu-card:nth-child(4n) {
    margin-right: 0;
}

.menu-card img {
    border-radius: 3px;
    margin: 0;
}

.menu-card h5 {
    margin: 5px 0 0;
    font-size: 14px;
}

.menu-card a {
    font-size: 12px;
}

.button {
    font-size: 14px;
    font-weight: 300;

    ion-icon {
        margin-right: 6px;
        transform: scale(.8);
    }
}

.action-cover {
    display: block;
    text-align: center;
    padding: 40px 30px;

    img {
        width: 100%;
        max-width: 420px;
    }

    .small-thumb {
        width: 100%;
        max-width: 180px;
    }

    p {
        font-size: 18px;
    }
}

.modal-default .list-md {
    margin: 0;
}
.modal-default h2 {
    font-size: 16px;
    padding-left: 0;
    margin-top: 0;
}
.modal-default .cart-view .product-detail-block {
    line-height: 20px;
}

.table-layout .input-field:focus,
.table-layout .input-field:active, 
.table-layout .input-field:focus-visible {
    border: 1px solid rgba(243, 74, 53, .36);
    outline: none;
}

@media screen and (max-width: 768px) {
    .button-inner {
        font-size: 12px;
    }

    .list-md {
        margin: 0px 10px 20px;
    }

    .no-form {
        max-width: 100%;
        margin-left: 0;
        padding: 10px;

    }

    .pagename {
        h2 {
            font-size: 15px;
            padding: 15px 15px 0;
            margin: 0;
        }
        strong {
            padding: 15px 15px 0;
        }
    }

    .banner-adv {
        margin-top: 10px px;
        border-radius: 5px;
    }

    .menu-card {
        max-width: calc(50% - 7px);
    }

    .menu-card:nth-child(2n) {
        margin-right: 0;
    }

    .meal-name h2 {
        font-size: 12px;
        margin: 10px 0;
    }

    .no-form {
        h4 {
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 0;
        }

        Ion-Item {
            margin-bottom: 0;
        }

        .sc-ion-input-md,
        .sc-ion-label-md-h,
        .ion-color-danger {
            font-size: 12px;
        }
    }

    .listing-block {
        .list-md {
            margin: 0 5px 10px;
        }
    }

    .mobile-view.display-card {
        margin: 5px;
        margin-top: 10px;
        &.order-in-progress {
            .meal-name {
                h2 {
                    padding-left: 0;
                }
            }
            a {
                font-size: 14px;
            }
            hr {
                margin: 10px;
            }
            .order-action-block{
                display: block;
                text-align: center;
                .button {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }

    .cart-count {
        color: #464646;
        transform: scale(.9);
    }

    .display-card.cart-view {
        border: none;
        font-size: 14px;
    }

    .cart-view {
        .cart-header {
            font-weight: 500;
        }

        .product-detail-block {
            line-height: 20px;
            font-size: 12px;

            img {
                height: 40px;
                width: 50px;
            }
        }

        .button {
            top: 3px
        }

        .input-count {
            position: relative;
            top: -3px;
        }
    }

    .mob-card-view {
        width: calc(100% - 30px);
        border: 1px solid #d6d6d6 !important;
        margin-top: 20px;
    }

    .mob-card-view-action {
        text-align: center;

        .button {
            margin: auto;
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }

    .review-view.mob-card-view {
        text-align: center;

        ion-col {
            text-align: center;
        }

        .item-interactive.item-textarea {
            margin-top: 20px !important;
        }
    }

    .product-image-block.md {
        text-align: center;
    }
    .display-card .product-image-block img {
        max-width: 200px
    }
    .timer-icon {
        top: 3px;
    }
    .product-progress-block ul li span {
        font-size: 14px;
    }
    .product-progress-block ul li ion-icon {
        transform: scale(1.2);
    }
    .product-progress-block ul li ion-spinner {
        transform: scale(.9);
    }
    .button ion-icon {
        transform: scale(1.2);
    }
}

@media screen and (max-width: 540px) {

    .small-form,
    .large-form {
        width: 100%;
        max-width: 360px;
        padding: 20px;
    }

    .product-listing-mob {
        text-align: center;

        .button {
            width: 100%;
            max-width: calc(100% - 30px);
            margin: auto;
            margin-bottom: 5px;
        }

        h2,
        p {
            text-align: center;
        }
    }
}

@media screen and (max-width: 420px) {

    .small-form,
    .large-form {
        width: 100%;
        max-width: calc(100% - 40px);

        h3 {
            text-align: center;
            margin-bottom: 10px;
            margin-top: 5px;
            font-size: 18px;
        }
    }
}

.hideMobileTabs,
.hideWebMenus {
    display: none !important;
}

/* */

.role-badge {
    font-size: 12px;
    margin-left: 10px;
    padding: 2px 6px;
    border-radius: 6px;
    background: #fabd99;
    position: relative;
    top: -3px;
}

.card-layout {
    display: block;

    .card-view {
        display: inline-block;
        width: calc(33% - 15px);
        border: 1px solid #d6d6d6;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 20px 10px;
        border-radius: 6px;
        position: relative;

        span {
            position: absolute;
            right: 10px;
            top: 15px;
            font-size: 30px;
            font-weight: 600;
        }
    }
}

.badge-veg,
.badge-non-veg {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    top: -1px;
}

.badge-veg {
    background: #30e648;
}

.badge-non-veg {
    background: #fa4343;
}

.table-layout {
    display: block;

    .input-field {
        background: #ffffff;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
        padding: 3px 2px;
        margin-left: 5px;

        &:active,
        :focus,
        :focus-visible {
            border: 1px solid #fa4343;
        }

        &.input-field-full {
            width: 100%;
            max-width: 320px;
        }
    }

    .table-wrapper {
        width: 100%;

        &.table-responsive {
            overflow-x: scroll;
        }

        table {
            width: 100%;
            border: none;

            tr {
                border-bottom: 1px solid #d6d6d6;
                text-align: left;

                th,
                td {
                    padding: 12px 6px;

                    .button {
                        font-size: 12px;
                        border-radius: 3px;
                        padding: 2px 6px;
                        margin-right: 5px;

                        &.green {
                            background: #c0fac3;
                            border: 1px solid #43fa4c;
                            color: #05970d;
                        }

                        &.red {
                            background: #faccc0;
                            border: 1px solid #f85549;
                            color: #e7281b;
                        }
                    }
                }
            }

            .button {
                height: 24px !important;
                padding: 0 !important;
                font-size: 10px !important;
                margin-top: 4px !important;
            }
        }
    }
}

/* swiper styles */
.swiper .swiper-slide {
    width: 150px !important;
    margin-right: 0 !important;
}
.swiper .swiper-slide .menu-card {
    max-width: 100%;
    min-height: 240px;
    text-align: left;
}
.swiper .swiper-slide .menu-card h5, 
.swiper .swiper-slide .menu-card p {
    margin: 0;
    line-height: 1.24;
    font-size: 14px;
}
@media (max-width:768px) {
    .swiper .swiper-slide {
        width: auto ;
        margin-right: auto;
    }
}

/* */
.menu-listing-nav .button ion-icon {
    transform: scale(1.2);
}

.get-status {
    margin: auto;
    display: block;
    max-width: calc(100% - 20px);
}
@media only screen and (min-width: 768px) and (min-height: 768px) {
    .large-modal-custom {
        --width: calc(100% - 40px);
        --height: 600px;
        --max-width: 1024px;
        table {
            width: 100%;
            text-align: left;
        }
    }
}

.colapse-icon {
    float: right;
    color: #000000;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
       opacity: .75;
    }
}

.item-card {
    width: calc(50% - 10px);
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;

    &:nth-child(2n) {
        margin-right: 0;
    }

    .item-image {
        width: 120px;
        float: left;

        img {
            border-radius: 5px;
            float: left;
        }
    }

    .item-description {
        width: calc(100% - 120px);
        float: left;
        padding-left: 30px;

        h5 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 5px;
        }

        a {
            cursor: pointer;
        }

        .food-veg {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            padding: 4px;
            display: inline-block;
            background: #ffffff;
            border: 1px solid #7fca16;
            position: relative;
            top: 4px;
            left: 4px;
            margin-left: 2px;
            .fill-space {
                width: 10px;
                height: 10px;
                border-radius: 6px;
                background: #7fca16;
                float: left;
                margin-top: 0px;
            }
        }
        .food-non-veg {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            padding: 4px;
            display: inline-block;
            background: #ffffff;
            border: 1px solid #ff0000;
            position: relative;
            top: 4px;
            left: 4px;
            margin-left: 2px;
            .fill-space {
                width: 10px;
                height: 10px;
                border-radius: 6px;
                background: #ff0000;
                float: left;
                margin-top: 0px;
            }
        }

    }
}

.order-card {
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 20px;

    .order-description {
        hr {
            margin: 10px 0;
        }
    }

    table {
        width: 100%;
        max-width: 420px;
        margin: 0;
        margin-bottom: 25px;
        padding: 0;
        border: 1px solid #d6d6d6;
        
        tbody {
            margin: 0;
            padding: 0;

            tr {
                border-bottom: 1px solid #d6d6d6;

                td {
                    width: 50%;
                    padding: 5px 10px;
                }
            }
        }
    }
}

.listing-block .accordion-animated {
    .item-label {
        margin-bottom: 20px;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .item-card {
        width: 100%;
    }

    .detail-block {
        .product-detail-block {
            font-size: 104%;
            padding: 0;
            padding-left: 5px;
        }
        .ingredients-list {
            margin: 0;
            list-style: none;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 480px) {
    .item-card {
        padding: 8px;
        margin-bottom: 8px;
        .item-image {
            width: 120px;
        }
        .item-description {
            width: calc(100% - 120px);
            padding-left: 20px;

            h5 {
                margin-bottom: 0;
            }
            p {
                margin-top: 5px;
                margin-bottom: 2px;
            }
        }
    }
    .listing-block .accordion-animated {
        .item-label { 
            margin-bottom: 12px;
        }
        .ion-padding {
            padding: 0;
        }
    } 
}
@media only screen and (min-width: 768px) and (min-height: 768px) {
    .large-modal-custom {
        --width: calc(100% - 40px);
        --height: 600px;
        --max-width: 1024px;

        table {
            width: 100%;
            text-align: left;
        }
    }
}

/* order status page styles */
.order-live-block {
    display: block;

    .half-block {
        width: 50%;
        padding: 0 10px;
        float: left;
        text-align: center;

        img {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        ul {
            list-style: none;
            padding: 0;
            text-align: left;
            margin-top: 20px;
            padding-top: 20px;
            border-top: 2px solid #d6d6d6;

            li {
                width: 120px;
                height: 70px;
                margin-right: 10px;
                margin-bottom: 10px;
                display: inline-block;
                border-radius: 5px;
                border: 1px solid #d6d6d6;
                font-size: 36px;
                font-weight: 700;
                vertical-align: middle;
                line-height: 70px;
                text-align: center;
            }
        }

        &.in-progress ul li {
            background: #fec9cf;
            border: 1px solid #fb415e;
        }

        &.is-ready ul li {
            background: #dde7ff;
            border: 1px solid #759cff;
        }
    }
}

/* print bill module styles */
.print-bill-popup {
    table tr th {
        font-weight: 700;
    }

    table tr th,
    table tr td {
        padding: 4px;
        width: 33%;
    }

    .menu-title-print,
    .menu-item-print,
    .menu-total-print,
    .menu-btn-print {
        width: 100%;
        font-size: 14px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .menu-title-print,
    .menu-btn-print {
        margin: auto;
        text-align: center;
    }

    .menu-btn-print button {
        margin: auto;
        margin-top: 20px;
        border-radius: 3px;
        height: 34px;
        font-size: 14px;
        font-weight: 300;
        padding: 10px 20px;
        background: #f34a35;
        color: #ffffff;
    }
}

@media print {
    .print-bill-popup {
        .menu-btn-print {
            display: none;
        }

        table tr th {
            font-weight: 700;
        }

        table tr th,
        table tr td {
            padding: 4px;
            width: 33%;
        }

        .menu-title-print,
        .menu-item-print,
        .menu-total-print,
        .menu-btn-print {
            width: 100%;
            font-size: 14px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .menu-title-print {
            margin: auto;
            text-align: center;
        }

        .menu-btn-print,
        .menu-btn-close {
            display: none;
        }
    }
}