.title-default {
    font-size: 18px;
    font-weight: 700;
    opacity: .7;
}

.meal-name h2 {
    font-size: 15px;
    font-weight: 700;
    opacity: .7;
    padding-left: 15px;
    margin-top: 20px;
}

.display-card {
    display: block;
    box-shadow: 0 0 0;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    margin: auto;
    margin-top: 10px;
}

.display-card .product-image-block img {
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.display-card .product-detail-block {
    display: block;
    padding-left: 20px;
}

.display-card .product-detail-block h3 {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
}

.display-card .product-detail-block .ingredients-list {
    margin: 6px 0;
    padding-left: 20px;
    display: inline-block;
    width: 100%;
}

.display-card .product-detail-block .ingredients-list li {
    width: 100%;
    float: left;
    font-size: 14px;
}

.display-card .product-action-block .button {
    margin-bottom: 14px;
}

.display-card .product-action-block {
    text-align: center;
    margin: auto;
    vertical-align: middle;
}

.display-card .product-action-block h3 {
    text-align: left;
}

.display-card .product-action-block p {
    font-size: 12px;
    opacity: .5;
}

.display-card .product-dropdown {
    position: absolute;
    top: 15px;
    right: 15px;
}

.filters-row .item-interactive.item-select,
.filters-row .searchbar-should-show-clear {
    box-shadow: 0 0 0;
    border: 1px solid #969696;
    border-radius: 5px;
    height: 36px;
    padding: 0;
}

.filters-row .list-md {
    margin: 0;
}

.filters-row .item-interactive.item-select {
    width: 100%;
    max-width: 150px;
    float: left;
    margin-right: 10px;
}

.filters-row.filter-row-mob .item-interactive.item-select {
    width: calc(50% - 5px);
}

.filters-row.filter-row-mob .item-interactive.item-select:nth-child(2n) {
    margin-right: 0px;
}

.filters-row.filter-row-mob ion-select {
    font-size: 12px;
}

.filters-row .searchbar-should-show-clear {
    width: 100%;
    max-width: 200px;
    float: right;
}

.filters-row .searchbar-input-container.sc-ion-searchbar-md-h {
    box-shadow: 0 0 0 !important;
}

.filters-row .searchbar-input.sc-ion-searchbar-md {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0 0 0;
    padding-inline-start: 37px;
    padding-inline-end: 37px;
}

.filters-row .searchbar-search-icon.sc-ion-searchbar-md {
    left: 8px;
    top: 5px;
    width: 20px;
    height: 20px;
}

.filters-row .md.in-item {
    padding: 0;
    top: -6px;
    position: relative;
}

.product-progress-block ul {
    padding: 0;
    padding-left: 15px;
    display: inline-block;
    width: 100%;
}

.product-progress-block ul li {
    list-style: none;
    position: relative;
}

.product-progress-block.progress-flat ul {
    padding: 10px 0 10px 50px;
    margin-top: 40px;
}

.product-progress-block.progress-flat ul li {
    width: calc(33% - 50px);
    float: left;
    margin-right: 50px;
}

.product-progress-block.progress-flat p {
    font-size: 16px;
    line-height: 1.6;
}

.product-progress-block.progress-flat p span {
    margin-bottom: 4px;
}

.product-progress-block ul li ion-icon {
    position: absolute;
    left: -30px;
    top: 5px;
    color: var(--ion-color-primary);
    transform: scale(1.6);
}

.product-progress-block ul li ion-spinner {
    position: absolute;
    left: -38px;
    top: -2px;
    color: var(--ion-color-primary);
    transform: scale(1.2);
}

.product-progress-block ul li span {
    display: block;
}

.product-progress-block ul li small {
    line-height: 1.4;
    display: block;
    margin-bottom: 10px;
}

.order-in-progress ion-item {
    --ion-background-color: transparent !important;
}

.meal-name {
    position: relative;
}

.timer-icon {
    position: absolute;
    right: 15px;
    top: -7px;
    font-size: 12px;
    height: 26px;
    line-height: 26px;
    display: flex;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding-right: 10px;
}

.timer-icon img {
    max-width: 16px;
    max-height: 16px;
    top: 4px;
    position: relative;
    margin-left: 5px;
    margin-right: 2px;
}

.timer-icon ion-icon {
    position: relative;
    transform: scale(.56);
    top: -5px;
}

ion-progress-bar {
    --background: var(--ion-color-warning-tint);
}

.banner-adv {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    margin-top: 20px;
}

.item-interactive.item-input {
    /* max-width: 40px; */
}

.cart-view .product-image-block {
    padding: 0 !important;
    display: flex;
}

.cart-view .product-image-block img {
    margin: 0;
}

.cart-view .input-count {
    display: flex;
    border: transparent;
    transform: scale(.8);
    border: none !important;
}

.cart-view .input-count .item-native {
    border: none !important;
}

.cart-view .product-detail-block {
    padding: 0;
    padding-left: 15px;
    line-height: 50px;
}

.cart-view .input-count .native-input {
    height: 50px;
    width: 55px;
}

.cart-view .button {
    margin: 0;
    position: relative;
}

.cart-view ion-grid {
    border-bottom: 1px solid #d6d6d6;
    padding: 13px 0;
}

.order-summary {
    display: flex;
    padding: 20px;
}

.order-summary table {
    width: 100%;
}

.order-summary table tr strong {
    display: block;
    padding: 10px 0;
}

.order-summary table tr td:nth-child(2) {
    text-align: right;
}

.cart-view ion-item.item-interactive.item-input.item-has-value.item.md.item-fill-none {
    padding: 0 !important;
    --padding-start: 0px;
    text-align: center;
    margin: auto;
}

.cart-view ion-item.item-interactive.item-input.item-has-value.item.md.item-fill-none ion-input {
    text-align: center !important;
    width: 60px;
    height: 60px;
}

.review-view,
.review-view ion-col {
    text-align: left;
}

.review-view ion-item ion-item {
    border: 1px solid #d6d6d6;
}

.review-view textarea,
.review-view .native-textarea.sc-ion-textarea-md {
    height: 150px;
    text-align: left;
    padding: 0 20px;
}

.review-view .product-detail-block {
    padding-left: 0;
}

.review-view .textarea-wrapper.sc-ion-textarea-md {
    max-height: 180px;
    overflow-y: scroll;
}

/* Rater css */
.react-rater-star.is-active {
    color: #fc6 !important;
}

.react-rater-star.will-be-active {
    color: #fd9 !important;
}

.react-rater-star {
    font-size: 2rem;
    cursor: pointer;
    color: #ccc;
    position: relative;
}

.product-info .product-detail-block {
    padding: 0;
}
.product-dropdown {
    display: none;
}