.menu-listing-nav {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
}

.menu-listing-nav .col-left,
.menu-listing-nav .col-right {
    display: inline-block;
    padding: 15px 20px;
    position: relative;
}

.menu-listing-nav .col-left {
    width: calc(100% - 240px);
    text-align: right;
}

.menu-listing-nav .col-right {
    width: 240px;
    float: right;
}

.menu-listing-nav .username {
    font-weight: 500;
}

.menu-listing-nav .userid {
    font-size: 12px;
}

.menu-listing-nav .dropdown {
    position: absolute;
    top: 5px;
    right: 20px;
}

.menu-listing-nav .notification {
    display: inline-block;
    margin-top: 7px;
    cursor: pointer;
}

.menu-listing-nav .notification .icon-large {
    fill: #4b4b4b;
    opacity: .6;
    transform: scale(.6);
}

.menu-listing-nav .notification.cart {
    background: #efeeed;
    border-radius: 5px;
    height: 30px;

}
.menu-listing-nav .cart-count {
    display: inline-block;
    position: relative;
    top: -10px;
    margin-left: 2px;
    margin-right: 8px;
}

.pagename h2 {
    font-size: 22px;
    padding-left: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.popover-side-bottom .list-md {
    padding: 0 ;
}
.popover-side-bottom .item-fill-none {
    border-bottom: 1px solid #d7d7d7;    
}
.popover-side-bottom ion-label {
    margin: 5px 0;
}
.popover-side-bottom .item-fill-none:last-child {
    border: none;
}
.popover-side-bottom .item-fill-none a {
    color: var(--ion-color-light-contrast);
    display: block;
    font-size: 12px;
}
.popover-side-bottom .item-fill-none span {
    font-size: 12px;
}
.popover-side-bottom .item-fill-none .input-wrapper {
    min-height: 38px;
}
.popover-side-bottom .list-md {
    margin: 0 5px;
}